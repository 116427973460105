export const fade = {
  offscreen: {
    opacity: 0
  },
  onscreen: {
    opacity: 1,
    transition: {
      ease: 'easeOut',
      duration: 0.6,
      delay: 0.3
    }
  }
}

export const fadeLeft = {
  offscreen: {
    x: 200,
    opacity: 0
  },
  onscreen: {
    x: 0,
    opacity: 1,
    transition: {
      ease: 'easeOut',
      duration: 0.6
    }
  }
}

export const fadeLeftDelay = {
  offscreen: {
    x: 200,
    opacity: 0
  },
  onscreen: {
    x: 0,
    opacity: 1,
    transition: {
      ease: 'easeOut',
      duration: 0.6,
      delay: 0.2
    }
  }
}

export const fadeRight = {
  offscreen: {
    x: -200,
    opacity: 0
  },
  onscreen: {
    x: 0,
    opacity: 1,
    transition: {
      ease: 'easeOut',
      duration: 0.6
    }
  }
}

export const fadeUp = {
  offscreen: {
    y: 50,
    opacity: 0
  },
  onscreen: {
    y: 0,
    opacity: 1,
    transition: {
      ease: 'easeOut',
      duration: 0.6
    }
  }
}

export const fadeUpDelay = {
  offscreen: {
    y: 50,
    opacity: 0
  },
  onscreen: {
    y: 0,
    opacity: 1,
    transition: {
      ease: 'easeOut',
      duration: 0.6,
      delay: 0.2
    }
  }
}