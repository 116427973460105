import * as React from 'react'
import { styled } from '@mui/system'
import { Box, Container } from '@mui/material'
import Lottie from 'lottie-react'
import { motion } from 'framer-motion'
import { fadeUp, fadeUpDelay } from '../../src/animationVariants'
import animationData1 from '../../public/animations/feature-01.json'
import animationData2 from '../../public/animations/feature-02.json'
import animationData3 from '../../public/animations/feature-03.json'

const interactivity = {
  mode: 'cursor',
  actions: [
    {
      position: { x: [0, 1], y: [0, 1] },
      type: 'play',
      frames: [0, 100]
    },
    {
      position: { x: -1, y: -1 },
      type: 'stop',
      frames: [0]
    }
  ]
}

const GridCol = styled(Box)(({ theme }) => ({
  textAlign: 'center',
  borderRadius: 20,
  width: '27%',
  padding: 20,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  position: 'relative',
  overflow: 'hidden',
  '&:before': {
    content: '""',
    position: 'absolute',
    top: '50%',
    left: '50%',
    width: '10%',
    height: '10%',
    transform: 'translateY(-50%, -50%)',
    borderRadius: '50%',
    zIndex: '-1',
    transition: 'all 0.5s ease'
  },
  '&:hover': {
    '&:before': {
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      backgroundColor: 'rgba(211, 218, 226, 0.43)',
      borderRadius: '23px',
      transition: 'all 0.2s ease'
    },
    '& svg': {
      backgroundColor: 'transparent !important'
    }
  },
  [theme.breakpoints.down('md')]: {
    width: '100%',
    background: '#d3dae2',
    marginBottom: 20,
    padding: '50px 20px',
    '&:hover': {
      background: '#d3dae2'
    }
  }
}))

const FlexWrap = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 30
  }
}))

const TitleWrap = styled(Box)(({ theme }) => ({
  position: 'relative',
  fontSize: '3.4375rem',
  margin: 0,
  lineHeight: 1.2,
  fontWeight: 900,
  [theme.breakpoints.down('md')]: {
    fontSize: '2.25rem'
  }
}))

const ColTitle = styled(Box)(({ theme }) => ({
  position: 'relative',
  fontSize: '1.5rem',
  fontWeight: 900,
  margin: '0 auto 28px',
  lineHeight: 1.25,
  maxWidth: '80%',
  [theme.breakpoints.down('md')]: {
    fontSize: '1.25rem',
    lineHeight: 1.2,
    textAlign: 'left',
    maxWidth: '75%',
    margin: '0 auto 0px'
  }
}))

const ColDesc = styled(Box)(({ theme }) => ({
  position: 'relative',
  fontSize: 15
}))

const ImageWrap = styled(Box)(({ theme }) => ({
  position: 'relative',
  width: 130,
  height: 130,
  overflow: 'hidden',
  borderRadius: 15,
  margin: '0 auto 30px',
  '& svg': {
    backgroundColor: 'rgba(211, 218, 226, 0.43)'
  },
  [theme.breakpoints.down('md')]: {
    height: 60,
    maxWidth: 60,
    margin: 0,
    marginRight: 10,
    '& svg': {
      width: '100%',
      height: '100%',
      transform: 'translate3d(0px, 0px, 0px)'
    }
  }
}))

export default function FeaturesWrap () {
  return (
    <Container
      maxWidth='xl'
      sx={{ pt: { xs: 7, md: 22 }, pb: { xs: 0, md: 7 }, mb: { xs: 2, md: 10 } }}
    >
      <Box sx={{ textAlign: 'center', mb: { xs: 2.5, md: 8 } }}>
        <motion.div
          variants={fadeUp}
          initial={{ opacity: 0, y: 20 }}
          whileInView='onscreen'
          viewport={{ once: false }}
        >
          <TitleWrap component='h2'>Features That Fit You</TitleWrap>
        </motion.div>
      </Box>
      <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, justifyContent: 'space-between' }}>
        <GridCol>
          <motion.div
            variants={fadeUpDelay}
            initial={{ opacity: 0, y: 50 }}
            whileInView='onscreen'
            viewport={{ once: false }}
          >
            <FlexWrap>
              <ImageWrap>
                <Lottie speed={2} animationData={animationData1} interactivity={interactivity} />
              </ImageWrap>
              <ColTitle>Unprecedented Personalization</ColTitle>
            </FlexWrap>
            <ColDesc>Altis uses AI to measure, track, and evaluate your exercise performance and create a personalized path that adapts to you.</ColDesc>
          </motion.div>
        </GridCol>
        <GridCol>
          <motion.div
            variants={fadeUpDelay}
            initial={{ opacity: 0, y: 100 }}
            whileInView='onscreen'
            viewport={{ once: false }}
          >
            <FlexWrap>
              <ImageWrap>
                <Lottie speed={2} animationData={animationData2} interactivity={interactivity} />
              </ImageWrap>
              <ColTitle>Real-time Form Guidance</ColTitle>
            </FlexWrap>
            <ColDesc>Altis tracks your body and provides immediate live feedback on your performance and exercise form.</ColDesc>
          </motion.div>
        </GridCol>
        <GridCol>
          <motion.div
            variants={fadeUpDelay}
            initial={{ opacity: 0, y: 120 }}
            whileInView='onscreen'
            viewport={{ once: false }}
          >
            <FlexWrap>
              <ImageWrap>
                <Lottie speed={2} animationData={animationData3} interactivity={interactivity} />
              </ImageWrap>
              <ColTitle>Intuitive Gesture and Voice Control</ColTitle>
            </FlexWrap>
            <ColDesc>With its easy and intuitive interface, Altis is ready to use out of the box.</ColDesc>
          </motion.div>
        </GridCol>
      </Box>
    </Container>
  )
}